import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout";
import Solutions from "/src/components/pages/solutions/Solutions";
import SolutionsIntro from "/src/components/pages/solutions/SolutionsIntro";

import {LottieAnimation} from "../../components/styled/lotti/LottieAnimations";

import circuit2 from "/static/assets/animations/circuit/circuit02.json";

const IoTSecurity = ({data}) => {

    const {iot} = data;

    return (
        <Layout lang={iot.lang} seo={iot.SEO}>
            <LottieAnimation animationData={circuit2} turned="true"/>
            <SolutionsIntro
                title={iot.title}
                subTitle={iot.subtitle}
                description={iot.description}
                button={iot.intro_button}
                buttonStyle="one-identity-primary"
            />
            <Solutions
                subTitle={iot.solution1.title}
                challangesCards={iot.solution1.solution_card}
                button={iot.solution1_button}
                buttonStyle="one-identity-primary"
            />
            <Solutions
                subTitle2={iot.solution2.title}
                solutionCards={iot.solution2.solution_card}
                button={iot.solution2_button}
                buttonStyle="one-identity-primary"
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSingleIotSecurity($locale: String) {
         iot: strapiIotSecurities(lang: { eq: $locale }) {
          lang
          title
          subtitle
          description
          intro_button {
            id
            name
            link
          }
          solution1 {
            title
            solution_card {
              avatar {
                url
              }
              id
              title
              description
            }
          }
          solution1_button {
            id
            name
            link
          }
          solution2 {
            title
            solution_card {
              avatar {
                url
              }
              id
              title
              description
            }
          }
          solution2_button {
            id
            name
            link
          }
          SEO {
            title
            isIndexable
            description
            keywords
            preview {
              url
            }
          }
      }
   }
 `

export default IoTSecurity;