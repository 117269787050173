import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import {Body, Header2, Title} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ButtonA, ButtonLink} from "../../styled/button/ButtonComponents";

const SolutionsIntro = ({title, subTitle, description, button, buttonStyle}) => {
    return (
        <SectionLayoutGrid id={"solution-description"} customBackground="middle">
            <Col>
                <Title color="white" marginBottom="medium">
                    {title}
                </Title>
                <Header2 color="white" marginBottom="small">
                    {subTitle}
                </Header2>
                <Body color="body-text">
                    {description}
                </Body>
            </Col>
            {button && (
                <Col>
                    {button.length === 1 &&
                    <ButtonLink to={button[0].link} color={buttonStyle}>
                        {button[0].name}
                    </ButtonLink>
                    }
                    {button.length === 2 && (
                        <>
                            <ButtonLink to={button[0].link} color={buttonStyle}>
                                {button[0].name}
                            </ButtonLink>
                            <ButtonA text={button[1].name} url={button[1].link} styleClass={global.btnBgSecondary}/>
                        </>
                    )}
                </Col>
            )}
        </SectionLayoutGrid>
    )
}

export default SolutionsIntro
